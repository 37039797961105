





























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { GridRow } from '@/types/cms/components/';
import { DataTableHeader } from 'vuetify/types/';
import { CloneHelper } from '@/utils/clonehelper';

/**
 * CompanyInformationList component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'home-company-information-form-dialog': () => import('./HomeCompanyInformationFormDialog.vue')
    }
})
export default class HomeCompanyInformationList extends Vue {
    // Prop of type boolean that defined whether the items are editable or not
    @Prop(Boolean) readonly editable: boolean | undefined;

    // array with all companyInformations
    @Prop({ type: Array as () => Array<GridRow> }) readonly companyInformations: Array<GridRow> | undefined;

    // search text
    private searchText = '';

    // the item of the new/edited companyInformation
    private editedCompanyInformationItem: GridRow = {
        id: -1,
        cols: [
            {
                id: 0,
                content: ''
            },
            {
                id: 1,
                content: ''
            }
        ]
    };

    // Default companyInformation item values
    private defaultCompanyInformationItem: GridRow = {
        id: -1,
        cols: [
            {
                id: 0,
                content: ''
            },
            {
                id: 1,
                content: ''
            }
        ]
    }

    // The index of the edited companyInformation (-1 = new companyInformation)
    private editedCompanyInformationIndex = -1;

    // Boolean that defines, whether the FormDialog will be displayed or not
    private showFormDialog = false;

    // Mode of the current form (CRUD string)
    private formDialogMode = 'create';

    /**
     * @returns Array with all table headers
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get tableHeaders(): Array<DataTableHeader> {
        const headers: Array<DataTableHeader> = [
            {
                text: this.$t('table.headers.id').toString(),
                value: 'id'
            },
            {
                text: this.$t('table.headers.text1').toString(),
                value: 'cols[0].content'
            },
            {
                text: this.$t('table.headers.text2').toString(),
                value: 'cols[1].content'
            }
        ];

        if (this.editable) {
            headers.push({
                text: this.$t('table.headers.actions').toString(),
                value: 'actions'
            });
        }
        
        return headers;
    }

    /**
     * Opens the CompanyInformationFormDialog to create a new companyInformation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private addCompanyInformation() {
        // get max id
        const customerInformationId = (this.companyInformations && this.companyInformations.length >= 1) ? this.companyInformations[this.companyInformations.length -1].id +1 : 0;

        // FIXME spread is copying reference?
        this.editedCompanyInformationItem = CloneHelper.deepCloneObject({...this.defaultCompanyInformationItem, id: customerInformationId });
        this.editedCompanyInformationIndex = -1;
        this.formDialogMode = 'create';
        this.showFormDialog = true;
    }

    /**
     * Opens the CompanyInformationFormDialog to edit the given companyInformation element
     * 
     * @param companyInformationItem companyInformation item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private editCompanyInformation(companyInformationItem: GridRow) {
        if (this.companyInformations) {
            this.editedCompanyInformationItem = CloneHelper.deepCloneObject(companyInformationItem);
            this.editedCompanyInformationIndex = this.companyInformations.indexOf(companyInformationItem);
            this.formDialogMode = 'update';
            this.showFormDialog = true;
        }
    }

    /**
     * Deletes the given companyInformation
     * 
     * @param companyInformationItem companyInformation item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private deleteCompanyInformation(companyInformationItem: GridRow) {
        if (this.companyInformations) {
            this.editedCompanyInformationItem = CloneHelper.deepCloneObject(companyInformationItem);
            this.editedCompanyInformationIndex = this.companyInformations.indexOf(companyInformationItem);
            this.formDialogMode = 'delete';
            this.showFormDialog = true;
        }
    }

    /**
     * Updates the edited item in the data table
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private updateEditedCompanyInformation(formDialogMode: string, companyInformationItemIndex: number, companyInformationItem: GridRow) {
        if (this.companyInformations) {
            if (formDialogMode == 'create') {
                this.companyInformations.push(companyInformationItem);
            }
            else if (formDialogMode == 'update') {
                Object.assign(this.companyInformations[companyInformationItemIndex], companyInformationItem);
            }
            else if (formDialogMode == 'delete') {
                this.companyInformations.splice(companyInformationItemIndex, 1);
            }

            this.showFormDialog = false;
        }
    }
}
